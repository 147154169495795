import React from "react";
import styled from "styled-components";
import {motion} from "framer-motion";

const HeroContainer = styled(motion.div)`
    background: url('/dojo-heros.jpg') center/cover;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media (max-width: 768px) {
        height: 300px;
    }
`;

const HeroContent = styled.div`
    background: rgba(0, 0, 0, 0.7);
    padding: 20px;
    border-radius: 10px;
    color: white;
    max-width: 80%;

    @media (max-width: 768px) {
        font-size: 14px;
        padding: 15px;
    }
`;

const Hero: React.FC = () => {
    return (
        <HeroContainer
            initial={{opacity: 0, scale: 0.8}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.8}}
        >
            <HeroContent>
                <h1>Karate iets voor jou?</h1>
                <p>Er is een goede manier om te weten of jij geschikt bent voor karate, probeer het gewoon eens uit
                    tijdens onze clubtraining op dinsdag.</p>
                <p>Losse; sportieve kledij volstaat. Aanmelden bij de zaal, sporthal de Weijer, 19:30 uur.</p>
            </HeroContent>
        </HeroContainer>
    );
};

export default Hero;
