import React from "react";
import styled from "styled-components";
import AnimationWrapper from "./AnimationWrapper";

const SectionContainer = styled.section`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 50px 20px;
    gap: 50px;
    max-width: 1200px;
    margin: auto;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 30px;
    }
`;

const Container = styled.div`
    flex: 1;
    text-align: center;
    background: ${({theme}) => theme.cardBackground};
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

    h2 {
        margin-bottom: 20px;
    }

    p {
        line-height: 1.8;
        font-size: 16px;
        color: ${({theme}) => theme.text};
    }
`;

const About: React.FC = () => {
    return (
        <AnimationWrapper delay={0.4}>
            <SectionContainer>
                <Container id="about">
                    <h2>WAT IS KARATE</h2>
                    <p>
                        Karate betekent letterlijk lege (Kara) hand (te): het is een manier
                        om je te leren verdedigen zonder wapens. Vuisten, handen, ellebogen,
                        knieën, voeten worden doeltreffend ingezet om je te verdedigen. Karate
                        is een ongewapende zelfverdedigingskunst, die karakterontwikkeling en
                        zelfbeheersing door training beoogt. Karate is daarbij ook een
                        "levenswijze", namelijk de samenvloeiing tussen lichaam en geest,
                        tussen mens en natuur, tussen aanvaller en verdediger. Dit betekent
                        dat karate je niet alleen zelfverdediging brengt, maar ook lenigheid,
                        goede lichaamshouding, conditie, lichaambeheersing, coördinatie,
                        reactie vermogen.
                    </p>
                </Container>
            </SectionContainer>
        </AnimationWrapper>
    );
};

export default About;
