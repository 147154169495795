import React from "react";
import styled from "styled-components";
import {FaClock, FaMapMarkerAlt, FaMoneyBillAlt, FaExclamationTriangle, FaImages, FaCommentDots} from "react-icons/fa";
import AnimationWrapper from "./AnimationWrapper";

const Section = styled.section`
    padding: 50px 20px;
    max-width: 1200px;
    margin: auto;
    text-align: center;
`;

const Title = styled.h2`
    margin-bottom: 40px;
    font-size: 28px;
`;

const CardsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const Card = styled.div`
    background: ${({theme}) => theme.cardBackground};
    color: ${({theme}) => theme.text};
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    }

    h3 {
        margin: 20px 0 10px;
        font-size: 20px;
    }

    p {
        line-height: 1.6;
        font-size: 16px;
    }

    svg {
        font-size: 40px;
        margin-bottom: 20px;
    }
`;

const TrainingInfo: React.FC = () => {
    return (
        <AnimationWrapper>
            <Section id="information">
                <Title>Training & Praktische Informatie</Title>
                <CardsContainer>
                    {/* Trainingstijden */}
                    <Card>
                        <FaClock/>
                        <h3>Trainingstijden</h3>
                        <p>
                            De karate trainingen zijn wekelijks op <strong>dinsdag 19:30 - 21:30 uur</strong>.
                            Deelname is mogelijk vanaf <strong>17 jaar</strong>.
                        </p>
                    </Card>

                    {/* Lesgeld */}
                    <Card>
                        <FaMoneyBillAlt/>
                        <h3>Lesgeld</h3>
                        <p>
                            <strong>€57,- per kwartaal</strong> <br/>
                            Inschrijfgeld: <strong>€15,- (eenmalig)</strong> <br/>
                            Tijdens de schoolvakanties zijn er geen lessen.
                        </p>
                    </Card>

                    {/* Dojo adres */}
                    <Card>
                        <FaMapMarkerAlt/>
                        <h3>Dojo adres</h3>
                        <p>
                            Sporthal de Weijer, Heer van Scherpenzeelweg 22, Mierlo.
                        </p>
                        <p>Email: karate@chello.nl</p>
                        <p>Tel: +316 3473 6113</p>
                    </Card>

                    {/* Aansprakelijkheid */}
                    <Card>
                        <FaExclamationTriangle/>
                        <h3>Aansprakelijkheid</h3>
                        <p>
                            De dojo wordt betreden op <strong>eigen risico</strong>. Noch karateschool Mierlo,
                            trainers, of leden zijn aansprakelijk voor blessures of schade. Bij inschrijving wordt
                            de aansprakelijkheidsuitsluiting aanvaard.
                        </p>
                    </Card>

                    {/* Beeldwerk */}
                    <Card>
                        <FaImages/>
                        <h3>Beeldwerk</h3>
                        <p>
                            Tijdens de lessen kunnen foto's gemaakt worden voor promotie. Indien u bezwaar heeft,
                            laat dit ons weten via e-mail.
                        </p>
                    </Card>

                    {/* Klachten */}
                    <Card>
                        <FaCommentDots/>
                        <h3>Klachten</h3>
                        <p>
                            Heeft u een klacht? Neem voor- of na de les contact op met Gerard. We streven altijd
                            naar een passende oplossing.
                        </p>
                    </Card>
                </CardsContainer>
            </Section>
        </AnimationWrapper>
    );
};

export default TrainingInfo;
