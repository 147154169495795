import React from "react";
import styled from "styled-components";
import AnimationWrapper from "./AnimationWrapper";

const SectionContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 50px 20px;
  gap: 50px;
  max-width: 1200px;
  margin: auto;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 30px;
  }
`;

const Container = styled.div`
  flex: 1;
  text-align: center;
  background: ${({ theme }) => theme.cardBackground};
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  h2 {
    margin-bottom: 20px;
  }

  p {
    line-height: 1.8;
    font-size: 16px;
    color: ${({ theme }) => theme.text};
  }
`;

const Divider = styled.div`
  width: 2px;
  background: ${({ theme }) => theme.text};
  @media (max-width: 768px) {
    width: 100%;
    height: 2px;
  }
`;

const AboutUs: React.FC = () => {
    return (
        <AnimationWrapper delay={0.4}>
            <SectionContainer>
                {/* Left Container */}
                <Container>
                    <h2>ONZE KARATESCHOOL</h2>
                    <p>
                        Karateschool Mierlo is september 2006 opgericht door Gerard en Manon
                        Schouten. De instructeur of 'Sensei' bij karateschool Mierlo is
                        Gerard Schouten (Yondan - 4e Dan). Hij is in 1979 gestart met karate
                        bij Funakoshi Alkmaar. In Heiloo heeft hij een aantal jaar
                        karateles gegeven. Vanaf 1996 is hij gaan trainen bij karate-do
                        Weng in Geleen en vanaf 2010 traint hij bij de BKSA Honbu dojo in
                        Hasselt (B). Sinds 2006 is hij de zeer enthousiaste instructeur van
                        karateschool Mierlo en hoopt dat zijn studenten net zo veel plezier
                        zullen beleven aan het beoefenen van karate zoals hij dat beleefd.
                    </p>
                </Container>

                {/* Divider */}
                <Divider />

                {/* Right Container */}
                <Container>
                    <h2>ONZE STIJL</h2>
                    <p>
                        We beoefenen Shotokan Karate volgens de leer van Gichin Funakoshi,
                        aangevuld met de methodieken van Taiji Kase Sensei. Karate betekent
                        letterlijk lege (Kara) hand (te): het is een manier om je te leren
                        verdedigen zonder wapens. Vuisten, handen, ellebogen, knieën, en
                        voeten worden doeltreffend ingezet om je te verdedigen. Shotokan
                        karate ontwikkelt jouw balans, snelheid, flexibiliteit en kracht. De
                        specifieke trainingstechnieken die we tijdens trainen gebruiken
                        zorgen - naast van de fysieke voordelen van karate - voor
                        zelfvertrouwen, motivatie en zelfrespect.
                    </p>
                </Container>
            </SectionContainer>
        </AnimationWrapper>
    );
};

export default AboutUs;
