import logo_light from "./logo_light.svg";
import logo_dark from "./logo.svg";

import slider_img_1 from "./slider/f22c95ae-dcf1-4874-aa88-385965d1d966.jpg"
import slider_img_4 from "./slider/slider_S1150098_trainers.jpg";
import slider_img_6 from "./slider/Slider_DSC08853.jpg";

import lamp from "./quicknav/lamp.jpg";
import documents from "./quicknav/documents.png";
import megaphone from "./quicknav/megaphone.png";

import whyUs from "./why-us/image1.jpg";

import gerard from "./trainers/PHOTO-2022Gerard.jpg";
import luc from "./trainers/PHOTO-2022Luc.jpg";
import tim from "./trainers/PHOTO-2022Tim.jpg";

export const logoLightImg = logo_light;
export const logoDarkImg = logo_dark;

export const sliderImg1 = slider_img_1;
export const sliderImg4 = slider_img_4;
export const sliderImg6 = slider_img_6;

export const lampImg = lamp;
export const documentsImg = documents;
export const megaphoneImg = megaphone;

export const whyUsImg = whyUs;

export const gerardImg = gerard;
export const lucImg = luc;
export const timImg = tim;