import { createGlobalStyle } from "styled-components";

export const lightTheme = {
    background: "#f5f5f5",
    text: "#111",
    cardBackground: "#fff",
    accent: "red",
    whyUsBackground:"#24263be6",
    whyUsTitle:"#fff",
    whyUsContent:"#fff",
    galleryBackground:"#24263be6",
    galleryTitle:"#fff",
};

export const darkTheme = {
    background: "#111",
    text: "#f5f5f5",
    cardBackground: "#222",
    accent: "red",
    whyUsBackground:"#24263be6",
    whyUsTitle:"#fff",
    whyUsContent:"#fff",
    galleryBackground:"#24263be6",
    galleryTitle:"#fff",

};

const GlobalStyles = createGlobalStyle`
    /* Reset default margins, paddings, and box-sizing */
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    /* Global body styles */
    body {
        margin: 0; /* Removes the default body margin */
        padding: 0;
        background: ${({ theme }) => theme.background};
        color: ${({ theme }) => theme.text};
        font-family: Arial, sans-serif;
        transition: all 0.3s linear;
    }

    /* Reset common elements */
    h1, h2, h3, h4, h5, h6, p {
        margin: 0;
        padding: 0;
    }

    ul, ol {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    a {
        text-decoration: none;
        color: inherit;
        transition: color 0.3s ease;
    }

    button {
        cursor: pointer;
        background: none;
        border: none;
        font-family: inherit;
        transition: all 0.3s ease;

        &:hover {
            transform: scale(1.05);
        }
    }

    /* Default heading styles */
    h1 {
        font-size: 2.5rem;
        line-height: 1.2;
    }

    h2 {
        font-size: 2rem;
        line-height: 1.3;
    }

    h3 {
        font-size: 1.75rem;
        line-height: 1.4;
    }

    h4, h5, h6 {
        font-size: 1.25rem;
        line-height: 1.5;
    }

    p {
        font-size: 1rem;
        line-height: 1.6;
    }

    /* Global scroll behavior */
    html {
        scroll-behavior: smooth; /* Adds smooth scrolling for anchor links */
    }

    /* Input and textarea resets */
    input, textarea {
        font-family: inherit;
        color: inherit;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px;
        transition: all 0.3s ease;

        &:focus {
            outline: none;
            border-color: ${({ theme }) => theme.accent};
        }
    }
`;

export default GlobalStyles;
