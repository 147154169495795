import React from "react";
import styled from "styled-components";
import AnimationWrapper from "./AnimationWrapper";

const SectionBG = styled.div`
    background: ${({theme}) => theme.galleryBackground};
`;

const GalleryContainer = styled.section`
    padding: 50px 20px;
    max-width: 1200px;
    margin: auto;
    text-align: center;

    h2 {
        color: ${({ theme }) => theme.galleryTitle};
        margin-bottom: 30px;
    }

    .gallery-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 20px;

        img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            border-radius: 10px;
            transition: transform 0.3s ease;

            &:hover {
                transform: scale(1.05);
            }
        }
    }
`;

const images = [
    "0b868038-0b2f-41d1-9072-f49401f1feda 2.JPG",
    "33c6ad7f-98a2-4f58-b016-ee1270368671.JPG",
    "8911d653-3067-4db2-846b-75c01b2d4a9b.JPG",
    "IMG_0166.jpg",
    "IMG_0180 2.jpg",
    "IMG_8081.JPG",
    "IMG_9786.JPG"
];

const Gallery: React.FC = () => {
    return (
        <AnimationWrapper>
            <SectionBG>
                <GalleryContainer id="gallery">
                    <h2>Karate Training Gallery</h2>
                    <div className="gallery-grid">
                        {images.map((src, index) => (
                            <img key={index} src={"/gallery/" + src} alt={`Karate Training ${index + 1}`}/>
                        ))}
                    </div>
                </GalleryContainer>
            </SectionBG>
        </AnimationWrapper>
    );
};

export default Gallery;
