import React from "react";
import styled from "styled-components";
import AnimationWrapper from "./AnimationWrapper";
import {gerardImg, lucImg, timImg} from "../assets";

const InstructorsContainer = styled.section`
  padding: 50px 20px;
  max-width: 1000px;
  margin: auto;
  text-align: center;
  color: ${({ theme }) => theme.text};

  h2 {
    margin-bottom: 30px;
  }

  .instructors-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }

  .instructor-card {
    background: ${({ theme }) => theme.cardBackground};
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }

    img {
      width: 100%;
      height: 350px;
      object-fit: cover;
      border-radius: 10px;
      margin-bottom: 10px;
    }

    h3 {
      margin: 10px 0 5px;
    }

    p {
      font-size: 14px;
      color: ${({ theme }) => theme.text};
    }
  }
`;

const instructors = [
    { name: "Gerard Schouten", bio: "Black Belt - 4th Dan KSKA.", image: gerardImg },
    { name: "Tim van Oorschot", bio: "Black Belt - 2th Dan KSKA.", image: timImg },
    { name: "Luc Bollen", bio: "Black Belt - 1st Dan.", image: lucImg },
];

const Instructors: React.FC = () => {
    return (
        <AnimationWrapper>
            <InstructorsContainer id="instructors">
                <h2>Onze Instructeurs</h2>
                <div className="instructors-grid">
                    {instructors.map((instructor, index) => (
                        <div className="instructor-card" key={index}>
                            <img src={instructor.image} alt={instructor.name} />
                            <h3>{instructor.name}</h3>
                            <p>{instructor.bio}</p>
                        </div>
                    ))}
                </div>
            </InstructorsContainer>
        </AnimationWrapper>
    );
};

export default Instructors;
