import React, {useEffect, useState} from "react";
import {Routes, Route} from "react-router-dom";
import {ThemeProvider} from "styled-components";
import GlobalStyles, {lightTheme, darkTheme} from "./styles/GlobalStyles";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import ScrollToTop from "./components/ScrollToTop";

export const ThemeContext = React.createContext({
    currentTheme: "light",
    toggleTheme: () => {
    }
});

const App: React.FC = () => {
    const [darkMode, setDarkMode] = useState(false);
    const [currentTheme, setCurrentTheme] = useState<"light" | "dark">("light");

    const toggleTheme = () => {
        let isDarkMode = !darkMode;
        setDarkMode(isDarkMode)
        setCurrentTheme(isDarkMode ? "dark" : "light");
    };

    // Use useEffect to apply the theme to the document body
    useEffect(() => {
        document.body.style.backgroundColor =
            currentTheme === "light" ? lightTheme.cardBackground : darkTheme.cardBackground;
    }, [currentTheme]);

    return (
        <ThemeContext.Provider value={{currentTheme, toggleTheme}}>
            <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
                <GlobalStyles/>
                <Navbar/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                </Routes>
                <ScrollToTop/>
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};

export default App;
