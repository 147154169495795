import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ThemeContext } from "../App";
import { FaMoon, FaSun } from "react-icons/fa";
import { logoLightImg, logoDarkImg } from "../assets";

const Nav = styled.nav`
    position: sticky;
    top: 0;
    z-index: 1000;
    background: ${({ theme }) => theme.cardBackground};
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    img {
        height: 54px;
    }

    h2 {
        font-size: 24px;
        margin: 0;
    }
`;

const NavLinks = styled.div`
    display: flex;
    gap: 20px;

    a {
        color: ${({ theme }) => theme.text};
        text-decoration: none;
        font-weight: bold;
        border-bottom: 2px solid transparent;
        transition: border-bottom 0.3s ease;

        &.active {
            color: red;
            border-bottom: 2px solid red;
        }

        &:hover {
            color: red;
        }
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

const HamburgerMenu = styled.div`
    display: none;
    cursor: pointer;
    font-size: 24px;
    color: ${({ theme }) => theme.text};

    @media (max-width: 768px) {
        display: block;
    }
`;

const MobileNavLinks = styled.div<{ isOpen: boolean }>`
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background: ${({ theme }) => theme.cardBackground};
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

    a {
        display: block;
        color: ${({ theme }) => theme.text};
        text-decoration: none;
        margin: 10px 0;
        font-weight: bold;
        border-bottom: 2px solid transparent;
        transition: border-bottom 0.3s ease;

        &.active {
            color: red;
            border-bottom: 2px solid red;
        }

        &:hover {
            color: red;
        }
    }
`;

const ToggleButton = styled.button`
    background: none;
    border: none;
    color: ${({ theme }) => theme.text};
    font-size: 18px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        color: red;
    }
`;

const Navbar: React.FC = () => {
    const { toggleTheme, currentTheme } = useContext(ThemeContext); // Access ThemeContext to toggle themes
    const [activeSection, setActiveSection] = useState<string>("home");
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleScroll = () => {
        const sections = document.querySelectorAll("section");
        const scrollY = window.pageYOffset;

        sections.forEach((section: any) => {
            const sectionTop = section.offsetTop - 70; // Adjust for navbar height
            const sectionHeight = section.offsetHeight;

            if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
                setActiveSection(section.getAttribute("id"));
            }
        });
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <Nav>
            <LogoContainer>
                <img src={currentTheme === "light" ? logoLightImg : logoDarkImg} alt="Dojomierlo" />
                <h2>KARATESCHOOL MIERLO</h2>
            </LogoContainer>
            <NavLinks>
                <a href="#home" className={activeSection === "home" ? "active" : ""}>
                    Home
                </a>
                <a href="#about" className={activeSection === "about" ? "active" : ""}>
                    Over
                </a>
                <a href="#instructors" className={activeSection === "instructors" ? "active" : ""}>
                    Instructeurs
                </a>
                <a href="#gallery" className={activeSection === "gallery" ? "active" : ""}>
                    Gallerij
                </a>
                <a href="#information" className={activeSection === "information" ? "active" : ""}>
                    Praktische Informatie
                </a>
                {/* Add Dark/Light Toggle Button */}
                <ToggleButton onClick={toggleTheme}>
                    {currentTheme === "light" ? <FaMoon/> : <FaSun />}
                </ToggleButton>
            </NavLinks>

            <HamburgerMenu onClick={toggleMenu}>☰</HamburgerMenu>

            {/* Mobile Navigation */}
            <MobileNavLinks isOpen={isMenuOpen}>
                <a href="#home" className={activeSection === "home" ? "active" : ""} onClick={toggleMenu}>
                    Home
                </a>
                <a href="#about" className={activeSection === "about" ? "active" : ""} onClick={toggleMenu}>
                    Over
                </a>
                <a href="#instructors" className={activeSection === "instructors" ? "active" : ""} onClick={toggleMenu}>
                    Instructeurs
                </a>
                <a href="#gallery" className={activeSection === "gallery" ? "active" : ""} onClick={toggleMenu}>
                    Gallerij
                </a>
                <a href="#information" className={activeSection === "information" ? "active" : ""} onClick={toggleMenu}>
                    Praktische Informatie
                </a>
                {/* Add Dark/Light Toggle Button */}
                <ToggleButton onClick={toggleTheme}>
                    {currentTheme === "light" ? <FaMoon/> : <FaSun/>}
                </ToggleButton>
            </MobileNavLinks>
        </Nav>
    );
};

export default Navbar;
