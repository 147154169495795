import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { whyUsImg } from "../assets";
import {FaCheck} from "react-icons/fa";

const Section = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; /* Full width of the screen */
    padding: 0;
    background: ${({ theme }) => theme.whyUsBackground}; /* Optional background color */

    @media (max-width: 768px) {
        flex-direction: column; /* Stack content for smaller screens */
    }
`;

const ImageContainer = styled(motion.div)`
    flex: 1; /* Take up equal space */
    height: 100%;
    img {
        width: 100%; /* Stretch the image to cover the container */
        height: 100%; /* Maintain full height */
        object-fit: cover; /* Ensures the image scales proportionally */
        display: block; /* Ensures no inline spacing issues */
        border-radius: 10px;
    }
`;

const ContentContainer = styled(motion.div)`
    flex: 1; /* Take up equal space */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 50px;
    color: ${({ theme }) => theme.text};

    @media (max-width: 768px) {
        padding: 20px;
        text-align: center;
    }

    h2 {
        font-size: 2rem;
        color: ${({ theme }) => theme.whyUsTitle};
        margin-bottom: 20px;

        @media (max-width: 768px) {
            font-size: 1.75rem;
        }
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            color: ${({ theme }) => theme.whyUsContent};
            font-size: 1.4rem;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            gap: 10px;

            @media (max-width: 768px) {
                font-size: 0.9rem;
            }
        }
    }
`;

const WhyUs: React.FC = () => {
    return (
        <Section>
            {/* Left: Image with Scroll Animation */}
            <ImageContainer
                initial={{ opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: true, amount: 0.5 }}
            >
                <img src={ whyUsImg } alt="Karate training" />
            </ImageContainer>

            {/* Right: Content with Scroll Animation */}
            <ContentContainer
                initial={{ opacity: 0, x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: true, amount: 0.5 }}
            >
                <h2>Waarom Karateschool Mierlo?</h2>
                <ul>
                    <li><FaCheck /> Alleen voor volwassenen.</li>
                    <li><FaCheck /> Les van ervaren instructeurs.</li>
                    <li><FaCheck /> Aandacht voor jouw persoonlijke doelen.</li>
                    <li><FaCheck /> Wij zijn een niet-commerciële club.</li>
                    <li><FaCheck /> Het gehele jaar mogelijkheid tot aanmelden.</li>
                </ul>
            </ContentContainer>
        </Section>
    );
};

export default WhyUs;
