import React from "react";
import Hero from "../components/Hero";
import About from "../components/About";
import WhyUs from "../components/WhyUs";
import Instructors from "../components/Instructors";
import Gallery from "../components/Gallery";
import BlogSection from "../components/BlogSection";
import TrainingInfo from "../components/TrainingInfo";
import styled from "styled-components";
import AboutUs from "../components/AboutUs";
import Footer from "../components/Footer";

const PageContainer = styled.div`
    width: 100%;
`;

const Home: React.FC = () => {
    return (
        <PageContainer>
            <section id="home">
                <Hero/>
            </section>

            <section id="about">
                <About/>
            </section>
            <section id="aboutus">
                <AboutUs/>
            </section>
            <section id="whyus">
                <WhyUs/>
            </section>

            <section id="instructors">
                <Instructors/>
            </section>

            <section id="gallery">
                <Gallery/>
            </section>

            <section id="contact">
                <TrainingInfo/>
            </section>
            <section>
                <Footer />
            </section>
        </PageContainer>
    );
};

export default Home;
