import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

interface AnimationWrapperProps {
    children: React.ReactNode;
    delay?: number;
    fadeDirection?: "up" | "down" | "left" | "right"; // Add fade direction
}

const AnimationWrapper: React.FC<AnimationWrapperProps> = ({ children, delay = 0, fadeDirection = "up" }) => {
    const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 });

    // Set animation direction
    const direction = {
        up: { opacity: 0, y: 50 },
        down: { opacity: 0, y: -50 },
        left: { opacity: 0, x: -50 },
        right: { opacity: 0, x: 50 },
    };

    return (
        <motion.div
            ref={ref}
            initial={direction[fadeDirection]}
            animate={inView ? { opacity: 1, y: 0, x: 0 } : {}}
            transition={{ duration: 0.8, delay }}
        >
            {children}
        </motion.div>
    );
};

export default AnimationWrapper;
