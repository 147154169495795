import React, {useContext} from "react";
import styled from "styled-components";
import {logoDarkImg, logoLightImg} from "../assets";
import {ThemeContext} from "../App";

const FooterContainer = styled.footer`
    text-align: center;
    padding: 20px;
    color: white;
    background: rgba(0, 0, 0, 0.8);
`;

const Footer: React.FC = () => {
    return (
        <FooterContainer>
            <img height="54px" src={logoDarkImg} alt="Dojomierlo"/>
            <p>&copy; 2025 <i>JansTech Solutions</i>. All Rights Reserved.</p>
        </FooterContainer>
    );
};

export default Footer;
